import { map, pathOr } from 'ramda';

import { MembershipNumber } from 'types/profile';

export const AIR_CODES = {
  '2F': 'Frontier Flying Service',
  '2H': 'Thalys',
  '3C': 'Air Chathams',
  '3M': 'Silver Airways',
  '3U': 'SICHUAN Airlines',
  '4M': 'Lan Argentina',
  '4N': 'Air North',
  '4O': 'Interjet',
  '4U': 'Germanwings',
  '4Z': 'Airlink',
  '5J': 'Cebu Pacific',
  '5T': 'CANADIAN NORTH',
  '5Z': 'CEM AIR',
  '6A': 'AVIACSA',
  '6E': 'Indigo',
  '6S': 'Saudi Gulf',
  '7F': 'First Air',
  '7H': 'Era Aviation',
  '7I': 'INSEL AIR',
  '8B': 'Caribbean Star Airlines',
  '8M': 'Myanmar Airways',
  '9F': 'Eurostar',
  '9T': 'Transwest Air',
  '9W': 'Jet Airways India',
  A3: 'Aegean Airlines',
  AA: 'American Airlines',
  AB: 'Air Berlin',
  AC: 'Air Canada',
  AD: 'AZUL/AZUL LINHAS AEREAS',
  AF: 'Air France',
  AH: 'Air Algerie',
  AI: 'Air India',
  AK: 'Air Asia',
  AM: 'Aeromexico',
  AP: 'Air One',
  AQ: 'Aloha Airlines',
  AR: 'Aerolineas Argentinas',
  AS: 'Alaska Airlines',
  AT: 'Royal Air Maroc',
  AV: 'AVIANCA',
  AY: 'Finnair',
  AZ: 'Alitalia',
  B0: 'La Compagnie',
  B6: 'JetBlue Airways Corporation',
  BA: 'British Airways',
  BD: 'bmi british midland',
  BE: 'Flybe',
  BG: 'Biman Bangladesh Airlines',
  BI: 'Royal Brunei',
  BP: 'Air Botswana',
  BR: 'EVA Airways',
  BT: 'AIR BALTIC CORPORATION S/A',
  BW: 'Caribbean Airlines',
  CA: 'Air China',
  CI: 'China Airlines',
  CJ: 'Avios',
  CM: 'Copa Airlines',
  CS: 'Continental Micronesia',
  CX: 'Cathay Pacific Airways',
  CY: 'Cyprus Airlines',
  CZ: 'China Southern',
  D7: 'Air Asia X',
  D8: 'Norwegian Air International',
  DD: 'Nok Air',
  DI: 'Norwegian Air UK',
  DL: 'Delta Airlines',
  DT: 'TAAG Angolan Airlines',
  DY: 'Norwegian Air Shuttle A.S.',
  EG: 'Japan Asia Airways',
  EI: 'Aer Lingus',
  EK: 'Emirates',
  EN: 'Air Dolomiti',
  ET: 'Ethiopian Airlines',
  EW: 'Eurowings',
  EY: 'Etihad Airways',
  F9: 'Frontier Airlines Inc.',
  FA: 'FlySafair',
  FB: 'Bulgaria Air',
  FI: 'Icelandair',
  FJ: 'Fiji Airways',
  FL: 'AirTran Airways',
  FY: 'FIREFLY',
  FZ: 'FLYDUBAI',
  G3: 'GOL Airlines',
  G8: 'Go Air',
  G9: 'Air Arabia',
  GA: 'Garuda',
  GE: 'TransAsia Airways',
  GF: 'Gulf Air',
  GL: 'Air Greenland',
  GR: 'AURIGNY AIR SERVICES',
  HA: 'Hawaiian Airlines',
  HC: 'Air Senegal',
  HF: 'Air Cote D Ivoire',
  HM: 'Air Seychelles',
  HU: 'Hainan Airlines',
  HX: 'Hong Kong Air',
  HY: 'UZ AIR PLUS',
  IB: 'Iberia',
  IC: 'Indian Airlines',
  ID: 'Batik Air',
  IG: 'MERIDIANA FLY',
  J2: 'AZERBAIJAN AIRLINES',
  J3: 'Northwestern Air',
  JC: 'JAL Express',
  JD: 'Japan Air System',
  JE: 'Mango',
  JJ: 'TAM Linhas Aeras',
  JL: 'Japan Airlines',
  JM: 'Air Jamaica',
  JQ: 'JetStar',
  JT: 'Lion Air',
  JU: 'AIR SERBIA',
  KA: 'Cathay Dragon',
  KC: 'Air Astana',
  KD: 'Kalstar',
  KE: 'Korean Air',
  KJ: 'British Mediterranean Airways',
  KL: 'KLM-Royal Dutch Airlines',
  KM: 'Air Malta',
  KP: 'Asky',
  KQ: 'Kenya Airlines',
  KU: 'Kuwait Airways',
  KX: 'Cayman Airways',
  L8: 'Air Luxor GB',
  LA: 'LATAM Airlines',
  LB: 'Lloyd Aereo Boliviano',
  LG: 'Luxair',
  LH: 'Lufthansa German Airlines AG',
  LI: 'LIAT',
  LK: 'Air Luxor',
  LM: 'Loganair',
  LO: 'LOT-Polish Airlines',
  LP: 'Lan Peru',
  LS: 'Jet2.com',
  LX: 'Swiss International Air',
  LY: 'El Al Israel Airlines',
  MA: 'Malev Hungarian',
  ME: 'Middle East Airlines',
  MF: 'Xiamen Airlines Company',
  MH: 'Malaysia Airlines',
  MK: 'Air Mauritius',
  MN: 'Kulula',
  MO: 'Calm Air',
  MS: 'Egyptair',
  MU: 'China Eastern Airlines',
  MX: 'Mexicana De Aviacion',
  NG: 'Lauda Air Luftfahrt Ag',
  NH: 'All Nippon Airways',
  NK: 'Spirit Airlines',
  NT: 'Binter',
  NZ: 'Air New Zealand',
  OA: 'Olympic Air',
  OB: 'BOA Airlines',
  OD: 'Malindo Air',
  OK: 'Czech Airlines',
  OS: 'Austrian',
  OZ: 'Asiana Airlines',
  P0: 'Proflight Zambia',
  P5: 'AeroRepublica',
  PD: 'Porter Airlines',
  PG: 'Bangkok Airways',
  PK: 'Pakistan International Airlines',
  PR: 'Philippine Airlines',
  PS: 'Ukraine International Airlines',
  PX: 'Air Niugini',
  PY: 'SURINAM AIRWAYS LTD',
  Q3: 'Zambian Airways',
  QF: 'Qantas Airways',
  QG: 'Citilinik',
  QH: 'Bamboo Airways – Bamboo Club',
  QR: 'Qatar Airways',
  R5: 'Malta Air Charter',
  RG: 'VARIG',
  RJ: 'Royal Jordanian',
  RO: 'TAROM',
  S7: 'Siberian Air',
  SA: 'South African Airways',
  SI: 'Blue Island',
  SJ: 'Sriwijaya',
  SK: 'SAS Scandinavian Airlines',
  SN: 'Brussels Airlines',
  SQ: 'Singapore Airlines',
  SU: 'Aeroflot',
  SV: 'Saudi Arabian Airlines',
  SW: 'Air Namibia',
  SY: 'Sun County Airlines',
  TA: 'Taca International Airlines',
  TC: 'Air Tanzania',
  TF: 'Braathens Regional Airlines',
  TG: 'Thai Airways International',
  TK: 'Turkish Airlines',
  TM: 'LAM Linhas aereas de Moçambique',
  TN: 'Air Tahiti Nui',
  TP: 'TAP Air Portugal',
  TR: 'Scoot Airlines',
  TS: 'Air Transat',
  TU: 'Tunis Air',
  TX: 'Air Caraibes',
  U2: 'Easyjet',
  UA: 'United Airlines',
  UB: 'Myanmar National Airlines',
  UK: 'Vistara Airline',
  UL: 'SriLankan Airlines',
  UU: 'Air Austral',
  UX: 'Air Europa',
  V7: 'Volotea',
  VA: 'Virgin Australia',
  VH: 'Aeropostal: Alas de Venezuela S.A.',
  VN: 'Vietnam Airlines',
  VS: 'Virgin Atlantic Airways',
  VW: 'AEROMAR',
  VX: 'Virgin America',
  VY: 'Vueling',
  W6: 'Wizzair',
  WB: 'RwandAir',
  WN: 'Southwest Airlines',
  WS: 'WestJet',
  WX: 'Cityjet',
  WY: 'Oman Air',
  XE: 'Express Jet',
  XL: 'Lan Ecuador',
  XR: 'Skywest Airlines',
  XX: 'Unlisted Air Number',
  XY: 'FlyNas',
  YP: 'Air Premia',
  YX: 'Midwest Airlines',
  ZB: 'Monarch Airlines',
  ZI: 'Azure Plus',
  ZL: 'Regional Express',
};

export const HOTEL_CODES = {
  '6C': 'IHG',
  AA: 'Americinn',
  AE: 'Amerihost',
  AJ: 'Amerisuites',
  AM: 'Adams Mark Hotels',
  AN: 'Ana Hotels',
  AZ: 'Ascott Hotels',
  BA: 'Boscolo Hotels',
  BH: 'Hawthorn Suites',
  BI: 'Best Inns',
  BK: 'Interstate Hotels and Resorts',
  BN: 'BARCELO HOTELS',
  BP: 'Shilo Inns',
  BR: 'Renaissance Htl Rsrt',
  BU: 'Baymont Inns',
  BW: 'Best Western',
  CC: 'Clarion Hotels',
  CD: 'Concorde Hotels',
  CG: 'City Lodge Hotel Group',
  CI: 'Comfort Inn',
  CJ: 'Caesar Park Hotels',
  CN: 'Conrad International Hotels',
  CP: 'Crowne Plaza Hotels',
  CQ: 'Club Quarters',
  CW: 'All Carlson Hospitality',
  CX: 'Country Inn Suites',
  CY: 'Courtyard Hotels',
  CZ: 'Comfort Suites',
  DE: 'Delta Hotels',
  DI: 'Days Inn',
  DO: 'DORINT HTL & RESORTS',
  DR: 'Drury Inn',
  DT: 'Doubletree Hotels',
  EC: 'Choice Hotels',
  EH: 'All Hilton',
  EL: 'Elements',
  EM: 'Marriott Bonvoy',
  EO: 'Econolodge',
  ES: 'Embassy Suites',
  ET: 'Marriott Conf Cntrs',
  EW: 'All SRS',
  FA: 'Fairmont Hotel',
  FH: 'Fiesta Americana',
  FI: 'Fraser Hospitality',
  FN: 'Fairfield Inn Hotels',
  FP: 'Four Points',
  FS: 'Four Seasons',
  FW: 'Flag Hotels',
  FX: 'First Hotels',
  GA: 'Global Alliance',
  GB: 'Macdonald Hotel Grp',
  GC: 'Chancellor Club',
  GF: 'Grange Hotels',
  GH: 'Grand Heritage',
  GI: 'Hilton Garden Inns',
  GR: 'Six Senses Hotel',
  GT: 'Golden Tulip Hotels',
  HG: 'Homewood Suites',
  HH: 'Hilton Hotels Corpo',
  HI: 'Holiday Inn',
  HJ: 'Howard Johnson',
  HL: 'Hilton International',
  HR: 'HARRAHS HOTELS',
  HU: 'Hyatt Vacation Club',
  HX: 'Hampton Inns',
  HY: 'Hyatt Hotels',
  IC: 'Intercontinental',
  IN: 'Hotel Indigo',
  IP: 'Roomzzz',
  IQ: 'MyFidelio',
  IT: 'Inter-Hotel',
  JC: 'Cendant Brands',
  JD: 'Doyle Collection',
  JH: 'Jolly Hotels',
  JI: 'Jurys Inn',
  JT: 'Jumeirah Hotels',
  JV: 'Joie de Vivre',
  KC: 'Kimpton Boutique Htl',
  KG: 'Knights Inn',
  KH: 'K Hotels',
  KI: 'Kempinski Htls/Rsts',
  LC: 'Luxury Collection',
  LD: 'Leonardo Hotels',
  LE: 'Luxe Hotels',
  LM: 'Punthill Group',
  LO: 'Langham Hotels',
  LQ: 'La Quinta Inns',
  LT: 'Travelodge Hotels',
  LU: 'Montcalm',
  LW: 'Leading Hotels',
  LX: 'Small Luxury Hotels',
  LZ: 'Loews Hotels',
  MC: 'Marriott Htls Rsts',
  MD: 'Le Meridien',
  ME: 'Melia Hotels International (ME)Tarjeta Mas',
  MH: 'Marco Polo Hotels',
  MM: 'Maritim Hotels',
  MO: 'Mandarin Oriental',
  MP: 'Mantra',
  MT: 'Microtel Inns',
  MU: 'MILLENNIUM',
  MV: 'MGM Mirage',
  MZ: 'Mainstay Suites',
  NH: 'Norlandia Hotels',
  NK: 'Nikko Hotels',
  NO: 'NEW OTANI AMERICAN',
  NR: 'Ramada International',
  NS: 'Nh Hoteles',
  NT: 'Strawberry Hotels',
  OB: 'Oberoi',
  OC: 'Okura Hotels',
  OH: 'Minor Hotels',
  OM: 'Omni',
  OR: 'Outrigger Hotels',
  OZ: 'Super 8',
  PD: 'Park Inn',
  PF: 'Pan Pacific',
  PH: 'Preferred Hotels',
  PJ: 'Prince Hotels',
  PK: 'Park Plaza',
  PL: 'Park Royal Hotel',
  PS: 'Sandman Hotels',
  PT: 'Prime Hotels',
  'PW': 'Elite Hotels',
  QF: 'Qantas Airways Htl',
  QG: 'Quest Serviced Apartments',
  QI: 'Quality Inns',
  QR: 'Quality Reservations',
  QT: 'Priority Guest Rewards',
  RA: 'Ramada',
  RC: 'Residence Inn Hotels',
  RD: 'Radisson Hotels',
  RF: 'Red Roof Inns',
  RG: 'Rydges',
  RL: 'Red Lion Hotels',
  RO: 'Rotana Hotels',
  RQ: 'Regal International',
  RR: 'Rihga Royal Hotels',
  RS: 'Rock Resorts',
  RT: 'Accor Hotels',
  RY: 'Rodeway Inns',
  RZ: 'Ritz Carlton Hotels',
  SB: 'Sofitel',
  SG: 'Shangrila Hotels',
  SH: 'Scandic Hotels',
  SI: 'Sheraton',
  SL: 'Swissotel',
  SM: 'Melia',
  SN: 'Sonesta',
  SO: 'SOKOS HOTELS',
  SR: 'STEIGENBERGER HOTELS',
  ST: 'Sorat Hotels',
  SU: 'Tsogo Sun',
  SV: 'Sarova Hotels',
  SX: 'Supranational Hotels',
  SY: 'Starhotels',
  SZ: 'Sleep Inn',
  TF: 'Thon Hotels',
  TH: 'Trident Privilege',
  TI: 'Thistle',
  TJ: 'Taj Hotels',
  TL: 'Travelodge',
  TO: 'Towneplace Suites',
  TP: 'Top Intl Hotels',
  TQ: 'Hyperion Hotels',
  TR: 'All Cendant',
  TW: 'Trump Hotels',
  TY: 'Paradores Club',
  UI: 'UTell',
  UZ: 'Lotte Hotel',
  VC: 'Marriott Vacation Club',
  VG: 'Viceroy Hotel Group',
  VL: 'Virgin Group',
  WB: 'Relais & Chateaux',
  WC: 'Westcoast Hotels',
  WF: 'All Westcoast Hospitality',
  WG: 'Wingate Inn',
  WH: 'W Hotels',
  WI: 'Westin Hotels',
  WK: 'Warwick Hotels',
  WL: 'Wellesley Inns',
  WV: 'Cambridge Suites',
  WW: 'World Hotels',
  WX: 'Coast Hotels',
  WY: 'Wyndham Hotels',
  XR: 'St Regis Luxury Collection',
  XS: 'Summerfield Suites',
  XV: 'Springhill Suites',
  XX: 'Unlisted Hotel Number',
  YO: 'Candlewood Suites',
  YS: 'Stamford Hotels',
  YX: 'Synxis',
  YZ: 'Staybridge Suites',
};

export const CAR_CODES = {
  AL: 'Alamo',
  BV: 'Bidvest Car Rental',
  DF: 'DriveForce',
  EP: 'EuropCar',
  ET: 'Enterprise',
  FX: 'Fox',
  LL: 'Localiza Rentacar',
  SX: 'Sixt Car Rental',
  XX: 'Unlisted Rental Car Number',
  ZA: 'Payless',
  ZD: 'Budget',
  ZE: 'Hertz',
  ZI: 'Avis',
  ZL: 'National',
  ZR: 'Dollar',
  ZT: 'Thrifty',
};

export const RAIL_CODES = {
  '2C': 'Sncf French Rail',
  '2H': 'Thalys',
  '2R': 'VIA Rail Canada',
  '2V': 'Amtrak',
  '5W': 'Italo',
  '7T': 'TRENITALIA',
  '9F': 'Eurostar',
  CF: 'Swiss Federal Railways',
  DB: 'DB Deutsche Bahn',
  I6: 'YO Club Iryo',
  OE: 'ÖBB VORTEILSCARD',
  RF: 'Renfe',
  SJ: 'Swedish Rail',
  SP: 'SBB CFF FFS',
};

export type CodeKey =
  | keyof typeof AIR_CODES
  | keyof typeof CAR_CODES
  | keyof typeof RAIL_CODES
  | keyof typeof HOTEL_CODES;

export type Codes = { [key in CodeKey]: string };

export const getCodes = (codes: Partial<Codes>) =>
  map((membershipNumber: MembershipNumber) => {
    const code = membershipNumber?.Vendor?.Code;
    return {
      label: code in codes ? codes?.[code] : 'N/A',
      value: pathOr('', ['Number'], membershipNumber),
    };
  });

import {
  ReactPlugin,
  withAITracking,
} from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { env } from 'env';
import jwtDecode from 'jwt-decode';
import { omit, pathOr } from 'ramda';
import { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import Wrapper from 'components/common/Wrapper/Wrapper';
import {
  useAgentData,
  useCurrentSection,
  useIsSmallScreen,
  useRawRouterQuery,
  useRouterQuery,
  useSavePreviousRoute,
} from 'hooks/general';

import {
  PAGES_WITHOUT_FEEDBACK,
  PAGES_WITHOUT_HEADER_AND_WRAPPER,
  PAGES_WITHOUT_HEADER_ON_SMALL_SCREEN,
  PAGES_WITHOUT_IDLE_TIMER,
  PAGES_WITHOUT_PHONE_VALIDATION,
  PAGES_WITHOUT_SHORTCUTS,
  PAGES_WITH_BREADCRUMBS,
  PAGES_WITH_PROFILE,
} from 'constants/general';

import FeedbackIcon from 'styles/icons/feedback.svg';
import { apiService, isAxiosError, refreshAccessToken } from 'utils/apiService';
import {
  authenticateGenesys,
  fetchConversationDetails,
} from 'utils/genesysAuth';
import { sendGoogleEvent } from 'utils/googleTag';
import { parse, stringify } from 'utils/qs';
import {
  getAccessToken,
  getGenesysAccessToken,
  getIsIdle,
  getPkce,
  getRefreshToken,
  hasPkce,
  requestToken,
  sendTokenToChromeExtension,
  setIsIdle,
  setTokens,
} from 'utils/token';

import Button from '../../common/Button/Button';

import { useMutation } from '@tanstack/react-query';
import { useOnboarding } from 'components/common/Onboarding';
import {
  authAtom,
  breadcrumbsAtom,
  emptyIsArrangersOrApprovers,
  isAuthedAtom,
  isFromArrangersOrApproversBlockAtom,
  phoneAssignmentAtom,
  profileSourceAtom,
  requireCallerAuthAtom,
  trackingSourceAtom,
} from 'state/store';
import { useInterval } from 'usehooks-ts';
import { relogin } from 'utils/common';
import styles from './Starting.module.scss';

import { useProfile } from 'hooks/profile';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { formatTime } from 'utils/trips';
import Header from './components/Header/Header';

export const inTS = <K extends string, O extends object>(
  key: K,
  object: O
): key is K & keyof O => key in object; // Because TypeScript is too dumb to do it

const clickTabIfPresent = (key: string) => {
  const tab = document.getElementById(key);
  if (tab) {
    tab.click();
  }
};
const useShortcuts = () => {
  const navigate = useNavigate();
  const requireCallerAuth = useAtomValue(requireCallerAuthAtom);
  const currentSection = useCurrentSection();
  const { onboardingStepIndex } = useOnboarding();
  const handleCtrlClick = (event: KeyboardEvent) => {
    const functionsMap = {
      a: () => clickTabIfPresent('Travellers'),
      d: () => clickTabIfPresent('DetailedTrips'),
      f: () => navigate('/'),
      o: () => clickTabIfPresent('CompanyProfile'),
      p: () => clickTabIfPresent('Profile'),
    };
    const key = event.key;
    if (
      PAGES_WITHOUT_SHORTCUTS.includes(currentSection) ||
      (requireCallerAuth && key !== 'f') ||
      !inTS(key, functionsMap) ||
      onboardingStepIndex !== undefined
    ) {
      return;
    }
    const functionToCall = functionsMap[key];
    if (!(event.ctrlKey && functionToCall)) return;
    event.preventDefault();
    functionToCall();
  };

  useEffect(() => {
    document.addEventListener('keydown', handleCtrlClick);

    return () => {
      document.removeEventListener('keydown', handleCtrlClick);
    };
  }, [handleCtrlClick]);
};
const useLogin = () => {
  const query = useRouterQuery();
  const rawQuery = useRawRouterQuery();
  const navigate = useNavigate();
  const setAuth = useSetAtom(authAtom);
  const { mutateAsync } = useMutation({
    mutationFn: ({
      code,
      codeVerifier,
    }: {
      code: string;
      codeVerifier: string;
    }) => requestToken(code, codeVerifier),

    mutationKey: ['requestToken'],
  });
  return async () => {
    const code = decodeURIComponent(query.code);
    setAuth({ type: 'LOADING' });
    try {
      const { codeVerifier } = getPkce();

      const response = await mutateAsync({ code, codeVerifier });
      setTokens(response);

      if (query.code) {
        const search = omit(['code', 'code_challenge'], rawQuery);
        setIsIdle(false);
        navigate(`${window.location.pathname}${stringify(search)}`, {
          replace: true,
        });
      }

      setAuth({ type: 'FULFILLED', value: response });
    } catch (error) {
      console.log('AUTH ERROR', error);
      if (!isAxiosError(error)) {
        return;
      }
      setAuth({ error, type: 'ERROR' });
    }
  };
};

export interface IParsePhoneNumberResponse {
  PhoneNumber: string;
  CountryCode: string;
  IsValid: boolean;
}
export const parsePhoneNumber = (phoneNumber: string) => {
  return apiService<IParsePhoneNumberResponse>({
    method: 'get',
    url: `/profile/parsedphonenumber?phoneNumber=${phoneNumber}`,
  });
};
const useFormatAndValidatePhone = () => {
  const dispatchSource = useSetAtom(profileSourceAtom);
  const dispatchPhoneAssignment = useSetAtom(phoneAssignmentAtom);
  const navigate = useNavigate();
  const currentSection = useCurrentSection();
  return async (qs: any) => {
    if (PAGES_WITHOUT_PHONE_VALIDATION.includes(currentSection)) {
      return;
    }
    const response = await parsePhoneNumber(qs.phone);
    if (!response) return;
    if (!response.IsValid) {
      delete qs.phone;
      delete qs.source;
      dispatchPhoneAssignment({ type: 'CLEAR' });
      dispatchSource({ type: 'CLEAR' });
      navigate(`${window.location.pathname}${stringify(qs)}`);
      return;
    }
    if (qs.phone.startsWith('%2B')) return;
    const phone = `+${response.CountryCode}${response.PhoneNumber}`;
    qs.phone = encodeURIComponent(phone);
    if (location.pathname === '/') {
      dispatchPhoneAssignment({ type: 'SET', value: phone });
    }

    navigate(`${window.location.pathname}${stringify(qs)}`);
  };
};

export const useLocationChange = () => {
  const location = useLocation();
  const breadcrumbs = useAtomValue(breadcrumbsAtom).value;
  const [profile, dispatchProfile] = useProfile();
  const login = useLogin();
  const dispatchBreadcrumbs = useSetAtom(breadcrumbsAtom);
  const dispatchSource = useSetAtom(profileSourceAtom);
  const dispatchTrackingSource = useSetAtom(trackingSourceAtom);
  const formatAndValidatePhone = useFormatAndValidatePhone();
  const onLocationChange = async () => {
    const { pathname, search } = location;
    const qs = parse(search);
    if (qs.code) {
      if (qs.code === 'automation') return;
      login();
      return;
    }
    const pkce = hasPkce();
    if (!pkce) {
      relogin();
      return;
    }

    if (qs.source) {
      dispatchSource({ type: 'SET', value: qs.source });
    }

    if (qs.trackingSource) {
      dispatchTrackingSource({ type: 'SET', value: qs.trackingSource });
    }

    if (qs.phone) {
      await formatAndValidatePhone(qs);
    }
    if (
      breadcrumbs?.length &&
      !PAGES_WITH_BREADCRUMBS.some((val) => pathname.includes(val))
    ) {
      dispatchBreadcrumbs({ type: 'CLEAR' });
    }
    if (!PAGES_WITH_PROFILE.some((val) => pathname.includes(val))) {
      if (profile) {
        dispatchProfile({ type: 'RESET' });
      }
    }
  };
  useEffect(() => {
    onLocationChange();
  }, [location]);
  useSavePreviousRoute();
};

const useIdle = () => {
  const navigate = useNavigate();
  const onIdle = () => {
    setIsIdle(true);
    navigate('/signed-out');
  };
  const currentSection = useCurrentSection();
  const minutes = Number(env.REACT_APP_IDLE_TIME || 15);
  const { getRemainingTime } = useIdleTimer({
    crossTab: true,
    disabled: PAGES_WITHOUT_IDLE_TIMER.includes(currentSection),
    onIdle,
    syncTimers: 200,
    timeout: minutes * 60 * 1000,
  });
  useHandleIdle();
  useInterval(() => {
    console.log(
      'TIME TILL CONSIDERED IDLE:',
      formatTime(Math.floor(getRemainingTime() / 1000), true, true)
    );
  }, 1000);
};
const useHandleIdle = () => {
  const currentSection = useCurrentSection();
  const navigate = useNavigate();
  const isAuthed = useAtomValue(isAuthedAtom);
  useEffect(() => {
    if (!isAuthed) return;
    const isIdle = getIsIdle();
    if (!isIdle) return;
    relogin();
  }, [isAuthed]);
};
const useAuthTokenValidation = () => {
  const auth = useAtomValue(authAtom);
  const location = useLocation();
  const routerQuery = useRouterQuery();
  const [isAuthed, setIsAuthed] = useAtom(isAuthedAtom);
  const validateToken = async () => {
    const token = getAccessToken();
    if (!token) {
      return false;
    }
    try {
      const exp = pathOr('', ['exp'], jwtDecode(token));
      if (Number(exp) > Math.floor(Date.now()) / 1000) {
        await sendTokenToChromeExtension({
          extensionId: env.REACT_APP_CHROME_EXTENSION_ID,
          jwt: token,
        });
        setIsAuthed(true);

        return true;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  useLocationChange();

  useEffect(() => {
    const checkToken = async () => {
      if (
        routerQuery?.conversationId ||
        location.hash?.includes('access_token')
      ) {
        await authenticateGenesys();
      }

      const isTokenValid = await validateToken();
      if (isTokenValid) {
        return;
      }
      if (getRefreshToken()) {
        await refreshAccessToken();
        const isNewAccessTokenValid = await validateToken();
        if (isNewAccessTokenValid) return;
      }
      if (!routerQuery.code) {
        relogin();
      }
    };
    if (auth.loading) return;
    checkToken();
  }, [auth.loading]);

  useEffect(() => {
    if (!auth.value) return;
    setIsAuthed(true); // TODO: isAuthed atom is not needed anymore since authAtom exists, research later
  }, [auth.value]);

  return { isAuthed };
};
const useGenesysAuth = () => {
  const isAuthed = useAtomValue(isAuthedAtom);
  const routerQuery = useRouterQuery();
  const navigate = useNavigate();
  const { mutate: redirectUsingGenesys } = useMutation({
    mutationFn: async () => {
      const result: any = await fetchConversationDetails(
        routerQuery?.conversationId
      );
      const value = result.data.participants[0];
      const pattern = value?.ani || (value?.address && value?.emails?.length);

      const response: any = await apiService({
        method: 'get',
        url: `/profileexists?Pattern=${pattern}`,
      });

      if (response.Result) {
        if (value?.address) {
          navigate(`/profile?email=${pattern}`);
        } else {
          navigate(`/profile?phone=${pattern}`);
        }
      }
    },
  });
  useEffect(() => {
    if (!(isAuthed && routerQuery?.conversationId && getGenesysAccessToken()))
      return;
    redirectUsingGenesys();
  }, [isAuthed, routerQuery?.conversationId]);
};

const useRequireCallerAuth = () => {
  const routerQuery = useRouterQuery();
  const [requireCallerAuth, setRequireCallerAuth] = useAtom(
    requireCallerAuthAtom
  );
  useEffect(() => {
    if (routerQuery.requireCallerAuth) {
      setRequireCallerAuth(true);
    }
  }, [routerQuery]);
  return { requireCallerAuth };
};

export const Starting = () => {
  const setIsFromArrangersOrApproversBlock = useSetAtom(
    isFromArrangersOrApproversBlockAtom
  );
  const currentSection = useCurrentSection();
  const routerQuery = useRouterQuery();
  const { isAuthed } = useAuthTokenValidation();
  const { requireCallerAuth } = useRequireCallerAuth();
  const navigate = useNavigate();
  useShortcuts();
  useGenesysAuth();
  useIdle();
  useShortcuts();
  useGenesysAuth();
  useAgentData();
  const isSmallScreen = useIsSmallScreen();
  if (!isAuthed) {
    return <main className={styles.page}></main>;
  }
  if (routerQuery.autoclose) {
    window.close();
  }
  if (PAGES_WITHOUT_HEADER_AND_WRAPPER.includes(currentSection)) {
    return <Outlet />;
  }
  if (
    PAGES_WITHOUT_HEADER_ON_SMALL_SCREEN.includes(currentSection) &&
    isSmallScreen
  ) {
    return <Outlet />;
  }
  const WrappedRoute = (
    <Wrapper className={styles.wrapper}>
      <Outlet />
    </Wrapper>
  );

  if (currentSection === 'componentPage') {
    return WrappedRoute;
  }
  return (
    <main className={styles.page}>
      {!PAGES_WITHOUT_FEEDBACK.includes(currentSection) && !requireCallerAuth && (
        <Button
          variant="primary"
          className={styles.floatContainer}
          onClick={() => {
            sendGoogleEvent('open_feedback');
            setIsFromArrangersOrApproversBlock(emptyIsArrangersOrApprovers);
            navigate('/feedback');
          }}
          customPadding
        >
          <img src={FeedbackIcon} alt="feedback" />
          <span>Feedback</span>
        </Button>
      )}
      <Header />
      <div className={styles.content}>{WrappedRoute}</div>
    </main>
  );
};

const wrapperFunc = () => {
  if (env?.REACT_APP_APPINSIGHTS_CONNECTIONSTRING) {
    const reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        connectionString: env.REACT_APP_APPINSIGHTS_CONNECTIONSTRING,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin as any],
      },
    });
    appInsights.loadAppInsights();
    return withAITracking(reactPlugin, Starting);
  }
  return Starting;
};

export default wrapperFunc();
